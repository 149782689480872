$(document).on('click', '#carousel #slide-opt-list .slide-opt-list-item', changeCarouselSlide);

var carouselIndex = 0;
var about_us_image_array = [
    '/images/landing/about-us/img-1.webp',
    '/images/landing/about-us/img-2.webp',
    '/images/landing/about-us/img-3.webp',
];
var about_us_image_pointer = 0;
$(document).ready(function(){
    setCarouselView();
    $('#boxes-container .box-container').hover(hoverWhatWeDo, unhoverWhatWeDo);
    setInterval(() => {
        changeAboutUsImage();
    }, 5000);
});
function setCarouselView(){
    $('#carousel .carousel-sub-container').removeClass('d-flex').addClass('d-none');
    $('#carousel #slide-'+(carouselIndex+1)).removeClass('d-none').addClass('d-flex');
    $('#carousel #slide-opt-list .slide-opt-list-item').removeClass('selected');
    $('#carousel #slide-opt-list .slide-opt-list-item[value="'+carouselIndex+'"]').addClass('selected');
}
function changeCarouselSlide(){
    carouselIndex = Number($(this).attr('value'));
    setCarouselView();
}
function hoverWhatWeDo(){
    var box_image = $(this).find('.box-image');
    box_image.attr('src', box_image.attr('hover'));
}
function unhoverWhatWeDo(){
    var box_image = $(this).find('.box-image');
    box_image.attr('src', box_image.attr('unhover'));
}   
function changeAboutUsImage(){
    about_us_image_pointer++;
    if(about_us_image_pointer >= about_us_image_array.length){
        about_us_image_pointer = 0;
    }
    $('#about-us-image-container').css('background-image', 'url("'+about_us_image_array[about_us_image_pointer]+'")');
}